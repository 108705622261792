<template>
	<div class="sales-quote-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !quoteNo"
		         v-on:ok="deleteSalesQuote"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">Delete Sales Quote</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="modalSalesQuoteNo" class="font-weight-bold">Quote No.</label>
					<input type="text" id="modalSalesQuoteNo" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': quoteNoInvalid}"
					       v-model="quoteNo"
					       v-on:input="quoteNoInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-50 h-100 rounded bg-white d-flex flex-column align-items-center flex-grow-0 overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="saveSalesQuote"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!salesQuote"
				                btn-title="DELETE"
				                v-bind:btn-icon="['fas', 'trash-alt']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="form-group position-relative w-100">
					<label for="salesQuoteNo" class="font-weight-bold">Quote No.</label>
					<input type="text" id="salesQuoteNo" placeholder=""
					       class="form-control position-relative"
					       v-bind:value="localSalesQuote.quoteNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="client" class="font-weight-bold">Client</label>
					<input type="text" id="client" class="form-control position-relative"
					       placeholder="Choose Your Intended Client From The Table"
					       v-bind:class="invalidInputs.client"
					       v-bind:value="localSalesQuote.clientName" readonly>
				</div>
				<div class="subform position-relative w-100 h-auto mb-3 border border-custom-light rounded"
				     v-for="(item, index) in localSalesQuote.items" v-bind:key="index">
					<div class="controls position-relative w-100 p-2 d-flex justify-content-center align-items-center">
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localSalesQuote.items.length === 1"
						        v-on:click="addDeleteItems('delete', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'minus']">
							</font-awesome-icon>
						</button>
						<h4 class="title position-relative px-4 m-0 text-truncate">Item {{index + 1}}</h4>
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localSalesQuote.items.length >= 50"
						        v-on:click="addDeleteItems('add', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'plus']">
							</font-awesome-icon>
						</button>
					</div>
					<div class="content position-relative w-100 p-3">
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-name${index}`" class="font-weight-bold">Name</label>
							<input type="text" v-bind:id="`item-name${index}`" placeholder="Item Name"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].name"
							       v-bind:value="item.name"
							       v-on:input="updateItems('name', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-quantity${index}`" class="font-weight-bold">Quantity</label>
							<input type="text" v-bind:id="`item-quantity${index}`" placeholder="Quantity"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].quantity"
							       v-bind:value="item.quantity"
							       v-on:input="updateItems('quantity', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-unitPrice${index}`" class="font-weight-bold">Unit Price</label>
							<input type="text" v-bind:id="`item-unitPrice${index}`" placeholder="Unit Price"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].unitPrice"
							       v-bind:value="item.unitPrice"
							       v-on:change="updateItems('unitPrice', $event.target.value, index)">
						</div>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="currency" class="font-weight-bold">Currency</label>
					<select id="currency" class="form-control position-relative"
					        v-bind:class="invalidInputs.currency"
					        v-bind:value="localSalesQuote.currency"
					        v-on:change="updateSalesQuote('currency', $event.target.value)">
						<option disabled hidden value=""></option>
						<option>HKD</option>
						<option>USD</option>
						<option>RMB</option>
						<option>JPY</option>
						<option>CAD</option>
					</select>
				</div>
				<div class="form-group position-relative w-100">
					<label for="serviceFee" class="font-weight-bold">Service Fee</label>
					<input type="text" id="serviceFee" placeholder="Service Fee"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.serviceFee"
					       v-bind:value="localSalesQuote.serviceFee"
					       v-on:change="updateSalesQuote('serviceFee', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="shippingFee" class="font-weight-bold">Shipping Fee</label>
					<input type="text" id="shippingFee" placeholder="Shipping Fee"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.shippingFee"
					       v-bind:value="localSalesQuote.shippingFee"
					       v-on:change="updateSalesQuote('shippingFee', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="otherCharges" class="font-weight-bold">Other Charges</label>
					<input type="text" id="otherCharges" placeholder="Other Charges"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.otherCharges"
					       v-bind:value="localSalesQuote.otherCharges"
					       v-on:change="updateSalesQuote('otherCharges', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="tax" class="font-weight-bold">Tax</label>
					<input type="text" id="tax" placeholder="Tax"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.tax"
					       v-bind:value="localSalesQuote.tax"
					       v-on:change="updateSalesQuote('tax', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="discount" class="font-weight-bold">Discount</label>
					<input type="text" id="discount" placeholder="Discount"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.discount"
					       v-bind:value="localSalesQuote.discount"
					       v-on:change="updateSalesQuote('discount', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="totalAmount" class="font-weight-bold">Total Amount</label>
					<input type="text" id="totalAmount" placeholder="0.00"
					       class="form-control position-relative"
					       v-bind:value="localSalesQuote.totalAmount" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="paymentTerms" class="font-weight-bold">Payment Terms</label>
					<input type="text" id="paymentTerms" placeholder="Payment Terms"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.paymentTerms"
					       v-bind:value="localSalesQuote.paymentTerms"
					       v-on:input="updateSalesQuote('paymentTerms', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="issuedBy" class="font-weight-bold">Quote Issued By</label>
					<input type="text" id="issuedBy" class="form-control position-relative"
					       placeholder="Choose Among Your Staffs Who Issued This Quote"
					       v-bind:class="invalidInputs.issuedBy"
					       v-bind:value="localSalesQuote.issuedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="issuedAtDate" class="font-weight-bold text-truncate">Date Issued</label>
						<b-form-datepicker id="issuedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.issuedAtDT.date"
						                   v-bind:value="localSalesQuote.issuedAtDT.date"
						                   v-on:input="updateDT('issuedAt', 'issuedAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="issuedAtTime" class="font-weight-bold text-truncate">Time Issued</label>
						<b-form-timepicker id="issuedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.issuedAtDT.time"
						                   v-bind:value="localSalesQuote.issuedAtDT.time"
						                   v-on:input="updateDT('issuedAt', 'issuedAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="notes" class="font-weight-bold">Notes</label>
					<textarea id="notes" rows="6" class="form-control position-relative"
					          v-model="localSalesQuote.notes"></textarea>
				</div>
				<div class="form-group position-relative w-100">
					<label for="updatedBy" class="font-weight-bold">Quote Updated By</label>
					<input type="text" id="updatedBy" class="form-control position-relative"
					       placeholder="Quote Not Yet Saved"
					       v-bind:value="localSalesQuote.updatedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="updatedAtDate" class="font-weight-bold text-truncate">Date Updated</label>
						<b-form-datepicker id="updatedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localSalesQuote.updatedAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="updatedAtTime" class="font-weight-bold text-truncate">Time Updated</label>
						<b-form-timepicker id="updatedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localSalesQuote.updatedAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="createdBy" class="font-weight-bold">Quote Created By</label>
					<input type="text" id="createdBy" class="form-control position-relative"
					       placeholder="Quote Not Yet Created"
					       v-bind:value="localSalesQuote.createdByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="createdAtDate" class="font-weight-bold text-truncate">Date Created</label>
						<b-form-datepicker id="createdAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localSalesQuote.createdAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="createdAtTime" class="font-weight-bold text-truncate">Time Created</label>
						<b-form-timepicker id="createdAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localSalesQuote.createdAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
			</form>
		</div>
		<div class="table-selects position-relative w-50 h-100 ml-3 rounded d-flex flex-column">
			<table-select title="Client" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="clientsFields"
			              v-bind:data-array="clients"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localSalesQuote.client]"
			              v-on:row-clicked="selectClient($event)"
			              v-bind:style="{height: '50% !important'}"></table-select>
			<table-select title="Staff" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="staffsFields"
			              v-bind:data-array="staffs"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localSalesQuote.issuedBy]"
			              v-on:row-clicked="selectIssuedBy($event)"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}"></table-select>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import TableSelect from "@/components/functional/table/TableSelect";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import isNumeric from "validator/lib/isNumeric";
import { BModal, BFormDatepicker, BFormTimepicker } from "bootstrap-vue";
import { format } from "date-fns"
import { dateTimeDifferent, itemsDifferent, calculateCharges, discountInvalid } from "@/utility/helpers";

export default {
	name: "SalesQuoteDetails",
	
	components: {
		FormControls,
		TableSelect,
		ControlButton,
		BModal,
		BFormDatepicker,
		BFormTimepicker
	},
	
	props: {
		// props from vue router route params
		salesQuoteId: {
			type: String,
		}
	},
	
	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},
	
	created() {
		this.initSalesQuote();
		window.addEventListener("beforeunload", this.unloadHandler);
	},
	
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},
	
	data() {
		return {
			localSalesQuote: {
				_id: "",
				quoteNo: "",
				client: "",
				items: [
					{
						name: "",
						quantity: "",
						unitPrice: "",
					},
				],
				currency: "",
				serviceFee: "",
				shippingFee: "",
				tax: "",
				otherCharges: "",
				discount: "",
				totalAmount: "",
				paymentTerms: "",
				issuedAt: "",
				createdAt: "",
				updatedAt: "",
				issuedBy: "",
				createdBy: "",
				updatedBy: "",
				notes: "",
				// added properties
				clientName: "",
				issuedByName: "",
				createdByName: "",
				updatedByName: "",
				issuedAtDT: { date: "", time: "" },
				createdAtDT: { date: "", time: "" },
				updatedAtDT: { date: "", time: "" },
			},
			// use v-model on input that does not require validation
			invalidInputs: {
				client: "",
				currency: "",
				paymentTerms: "",
				serviceFee: "",
				shippingFee: "",
				tax: "",
				otherCharges: "",
				discount: "",
				// can be invalid but not required
				issuedAtDT: { date: "", time: "" },
				issuedBy: "",
			},
			invalidItems: [
				{
					name: "",
					quantity: "",
					unitPrice: "",
				},
			],
			clientsFields: [
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Client No.", key: "clientNo", classBinding: ["f-3"] },
				{ display: "Type", key: "type", classBinding: ["f-2"] },
			],
			staffsFields: [
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Staff No.", key: "staffNo", classBinding: ["f-3"] },
				{ display: "Gender", key: "gender", classBinding: [] },
				{ display: "Position", key: "position", classBinding: ["f-4"] },
			],
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			password: "",
			pwInvalid: false,
			quoteNo: "",
			quoteNoInvalid: false,
		}
	},
	
	computed: {
		salesQuote() {
			return this.$store.state.contents.salesQuotations.find(({ _id }) => _id === this.salesQuoteId);
		},
		
		savable() {
			// fall through pattern
			if (!this.salesQuote) return true;
			// can safely access properties inside this.salesQuote due to the first if clause in this function
			if (itemsDifferent(this.localSalesQuote.items, this.salesQuote.items)) return true;
			if (dateTimeDifferent(this.salesQuote.issuedAt, this.localSalesQuote.issuedAt)) return true;
			const keys = ["client", "currency", "serviceFee", "shippingFee", "tax", "otherCharges", "discount",
				"paymentTerms", "issuedBy", "notes"];
			return keys.some(key => this.localSalesQuote[key] !== this.salesQuote[key]);
		},
		
		clients() {
			return this.$store.state.contents.clients.map(({ _id, name, clientNo, type }) => {
				return {
					_id: _id,
					name: name,
					clientNo: clientNo,
					type: type
				}
			});
		},
		
		staffs() {
			return this.$store.state.contents.staffs.map(({ _id, firstName, lastName, preferredName, staffNo, position, gender }) => {
				return {
					_id: _id,
					name: `${lastName} ${firstName}, ${preferredName}`,
					staffNo: staffNo,
					gender: gender,
					position: position
				};
			});
		},
	},
	
	methods: {
		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},
		
		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.password = "";
				this.pwInvalid = false;
				this.quoteNo = "";
				this.quoteNoInvalid = false;
			}
		},
		
		initSalesQuote() {
			if (this.salesQuote) {
				const obj = {...this.salesQuote};
				obj.items = [];
				this.invalidItems = [];
				this.salesQuote.items.forEach(item => {
					obj.items.push({...item});
					this.invalidItems.push({ name: "", quantity: "", unitPrice: "" });
				});
				const client = this.$store.state.contents.clients.find(({ _id }) => _id === obj.client);
				const issuedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.issuedBy);
				const createdBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.createdBy);
				const updatedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.updatedBy);
				// createdAt and updatedAt must present in the database, need no nullish check
				const createdAtDT = new Date(obj.createdAt);
				const updatedAtDT = new Date(obj.updatedAt);
				// added properties
				obj.clientName = client ? `${client.name} (${client.clientNo})` : "";
				obj.issuedByName = issuedBy ? `${issuedBy.lastName} ${issuedBy.firstName}, ${issuedBy.preferredName} (${issuedBy.staffNo})` : "";
				obj.createdByName = createdBy ? `${createdBy.lastName} ${createdBy.firstName}, ${createdBy.preferredName} (${createdBy.staffNo})` : "N/A";
				obj.updatedByName = updatedBy ? `${updatedBy.lastName} ${updatedBy.firstName}, ${updatedBy.preferredName} (${updatedBy.staffNo})` : "N/A";
				obj.createdAtDT = { date: format(createdAtDT, "yyyy-MM-dd"), time: format(createdAtDT, "HH:mm:ss")};
				obj.updatedAtDT = { date: format(updatedAtDT, "yyyy-MM-dd"), time: format(updatedAtDT, "HH:mm:ss")};
				if (obj.issuedAt) {
					const issuedAtDT = new Date(obj.issuedAt);
					obj.issuedAtDT = { date: format(issuedAtDT, "yyyy-MM-dd"), time: format(issuedAtDT, "HH:mm:ss")};
				} else {
					obj.issuedAtDT = { date: "", time: "" };
				}
				this.localSalesQuote = obj;
			} else {
				this.localSalesQuote._id = uuidv4();
				this.localSalesQuote.quoteNo = "QUO-" + this.localSalesQuote._id.split("-")[0].toUpperCase();
			}
		},
		
		addDeleteItems(option, index) {
			if (option === "add") {
				this.localSalesQuote.items.splice(index + 1, 0, { name: "", quantity: "", unitPrice: "" });
				this.invalidItems.splice(index + 1, 0, { name: "", quantity: "", unitPrice: "" });
			} else {
				this.localSalesQuote.items.splice(index, 1);
				this.invalidItems.splice(index, 1);
			}
		},
		
		selectClient(client) {
			this.localSalesQuote.client = client._id;
			this.localSalesQuote.clientName = `${client.name} (${client.clientNo})`;
			this.invalidInputs.client = "";
		},
		
		selectIssuedBy(staff) {
			this.localSalesQuote.issuedBy = staff._id;
			this.localSalesQuote.issuedByName = `${staff.name} (${staff.staffNo})`;
			this.invalidInputs.issuedBy = "";
		},
		
		// this method also set discount class to invalid if discountInvalid returns true
		updateTotalAmount() {
			const totalCharges = calculateCharges(this.localSalesQuote);
			if (discountInvalid(totalCharges, this.localSalesQuote.discount)) {
				this.invalidInputs.discount = "invalid";
				this.localSalesQuote.totalAmount = totalCharges.toFixed(2);
			} else {
				this.invalidInputs.discount = "";
				const totalAmount = totalCharges - parseFloat(this.localSalesQuote.discount);
				this.localSalesQuote.totalAmount = totalAmount.toFixed(2);
			}
		},
		
		updateSalesQuote(key, value) {
			this.localSalesQuote[key] = value;
			switch (key) {
				case "serviceFee":
				case "shippingFee":
				case "tax":
				case "otherCharges":
					if (isNumeric(value) && parseFloat(value) >= 0) {
						this.localSalesQuote[key] = parseFloat(value).toFixed(2);
						this.invalidInputs[key] = "";
					} else {
						this.invalidInputs[key] = "invalid";
					}
					this.updateTotalAmount();
					break;
				case "discount":
					if (isNumeric(value) && parseFloat(value) >= 0) this.localSalesQuote[key] = parseFloat(value).toFixed(2);
					this.updateTotalAmount();
					break;
				default:
					// check if value is just white spaces
					this.invalidInputs[key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},
		
		updateDT(key, keyDT, dateTime, value) {
			this.localSalesQuote[keyDT][dateTime] = value;
			this.invalidInputs[keyDT][dateTime] = "";
			if (dateTime === "date") {
				this.localSalesQuote[key] = this.localSalesQuote[keyDT].time ?
					new Date(`${value}T${this.localSalesQuote[keyDT].time}`).toISOString() : value;
			} else {
				this.localSalesQuote[key] = this.localSalesQuote[keyDT].date ?
					new Date(`${this.localSalesQuote[keyDT].date}T${value}`).toISOString() : value;
			}
		},
		
		updateItems(key, value, index) {
			this.localSalesQuote.items[index][key] = value;
			switch (key) {
				case "quantity":
					this.invalidItems[index][key] = isNumeric(value) && parseFloat(value) >= 0 ? "" : "invalid";
					this.updateTotalAmount();
					break;
				case "unitPrice":
					// bind to change event instead of input event
					if (isNumeric(value) && parseFloat(value) >= 0) {
						this.localSalesQuote.items[index][key] = parseFloat(value).toFixed(2);
						this.invalidItems[index][key] = "";
					} else {
						this.invalidItems[index][key] = "invalid";
					}
					this.updateTotalAmount();
					break;
				default:
					this.invalidItems[index][key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},
		
		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const quoteKeys = ["client", "currency", "paymentTerms", "serviceFee", "shippingFee", "tax", "otherCharges",
				"discount"];
			const itemKeys = ["name", "quantity", "unitPrice"];
			quoteKeys.forEach(key => {
				if (!this.localSalesQuote[key] ||
					this.localSalesQuote[key].trim().length === 0) this.invalidInputs[key] = "invalid";
			});
			this.invalidItems.forEach((item, index) => itemKeys.forEach(key => {
				if (!this.localSalesQuote.items[index][key] ||
					this.localSalesQuote.items[index][key].trim().length === 0) item[key] = "invalid"; //object reference
			}));
			if (this.localSalesQuote.issuedBy || this.localSalesQuote.issuedAt) {
				if (!this.localSalesQuote.issuedBy)this.invalidInputs.issuedBy = "invalid";
				if (!this.localSalesQuote.issuedAtDT.date) this.invalidInputs.issuedAtDT.date = "invalid";
				if (!this.localSalesQuote.issuedAtDT.time) this.invalidInputs.issuedAtDT.time = "invalid";
			}
			return Object.keys(this.invalidInputs).some(key => {
				if (key === "issuedAtDT") {
					return this.invalidInputs.issuedAtDT.date === "invalid" ||
						this.invalidInputs.issuedAtDT.time === "invalid";
				} else {
					return this.invalidInputs[key] === "invalid";
				}
			}) || this.invalidItems.some(item => itemKeys.some(key => item[key] === "invalid"));
		},
		
		async saveSalesQuote() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				const deleteKeys = ["clientName", "issuedByName", "createdByName", "updatedByName",
					"issuedAtDT", "createdAtDT", "updatedAtDT"];
				const now = new Date().toISOString();
				const salesQuote = {...this.localSalesQuote};
				if (!salesQuote.createdAt) {
					salesQuote.createdAt = now;
					salesQuote.createdBy = this.$store.state.user.user._id;
				}
				salesQuote.updatedAt = now;
				salesQuote.updatedBy = this.$store.state.user.user._id;
				deleteKeys.forEach(key => delete salesQuote[key]);
				await this.$store.dispatch("contents/updateContent", { key: "salesQuotations", value: salesQuote});
				if (this.salesQuoteId) {
					this.initSalesQuote();
					this.msg = "Previous Changes Saved!";
					this.msgInvalid = false;
				} else {
					// bypassing beforeRouteLeave guard
					this.msg = "Leave Without Saving?";
					await this.$router.push(`/sales/quotation/${salesQuote._id}`);
					// need to run initSalesQuote after route change since salesQuoteId prop was undefined
					this.initSalesQuote();
					// vue router reuses this component
					this.msgInvalid = false;
					this.msg = "New Sales Quote Created!";
				}
			}
		},
		
		async deleteSalesQuote(bvModalEvent) {
			if (this.password !== this.$store.state.user.user.password) {
				bvModalEvent.preventDefault();
				this.pwInvalid = true;
				if (this.quoteNo !== this.salesQuote.quoteNo) this.quoteNoInvalid = true;
			} else if (this.quoteNo !== this.salesQuote.quoteNo) {
				bvModalEvent.preventDefault();
				this.quoteNoInvalid = true;
			} else {
				await this.$store.dispatch("contents/deleteContent", { key: "salesQuotations", _id: this.salesQuote._id});
				// bypassing beforeRouteLeave guard
				this.msg = "Leave Without Saving?";
				await this.$router.push("/sales/quotation");
			}
		},
		
		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/sales/quotation");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
/*.container-form {
	overflow-y: scroll;
	
	.subform {
		// bootstrap border width overwrite
		border-width: 3px !important;
		
		.controls {
			height: 40px;
			
			.button {
				width: 24px;
				height: 24px;
			}
			
			.title {
				min-width: 50px;
			}
		}
	}
	
	.date-picker-form-group {
		width: 65%;
		min-width: 0;
		
		label {
			min-width: 0;
		}
		
		.date-picker {
			// overwrite picker label
			:last-child {
				white-space: nowrap !important;
				overflow: hidden !important;
				text-overflow: ellipsis !important;
			}
			
			&.invalid {
				box-shadow: $shadow-input-invalid !important;
			}
		}
	}
	
	.time-picker-form-group {
		width: 35%;
		min-width: 0;
		
		label {
			min-width: 0;
		}
		
		.time-picker {
			// overwrite picker label
			:last-child {
				white-space: nowrap !important;
				overflow: hidden !important;
				text-overflow: ellipsis !important;
			}
			
			&.invalid {
				box-shadow: $shadow-input-invalid !important;
			}
		}
	}
}

input.invalid {
	box-shadow: $shadow-input-invalid !important;
}

select.invalid {
	box-shadow: $shadow-input-invalid !important;
}*/
</style>